<template>
  <div class="support-ticket">
    <SupportTicketSingleItem />
  </div>
</template>

<script>
import SupportTicketSingleItem from "@/components/SupportTicketSingleItem.vue";

export default {
  name: "Support",
  title: "Shyplite - Support Ticket",
  components: {
    SupportTicketSingleItem
  },
};
</script>
