<template>
  <div class="container-fluid">
    <div class="border-bottom mx-n20">
      <div class="row align-items-center p-20">
        <div class="col-6">
          <span class="text-gray-600">
            Case:
            <span class="text-gray-900">
              Wrong Address Correction Charges
            </span>
          </span>
        </div>
        <div class="col-6 text-right">
          <b-button class="h40px p-12 mr-8">
            <i class="icon-phone"></i>
          </b-button>
          <b-button class="btn-red h40px p-12 mr-8">
            <i class="icon-delete"></i>
          </b-button>
          <b-button class="btn-green h40px p-12">
            <i class="icon-booked"></i>
          </b-button>
        </div>
      </div>
    </div>

    <div class="row pt-20">
      <div class="col-auto">
        <b-button variant="icon-sm" class="mt-5" @click="goBack">
          <i class="icon-previous fs9"></i>
        </b-button>
      </div>
      <div class="col-10">
        <h1>
          #204 - Status of some DZ, DL and XB shipments showing Delivered
        </h1>
        <span class="d-block text-gray-600 fw500 py-20">
          AWB:
          <span
            class="py-3 px-4 bg-gray-200 rounded-sm"
            v-b-tooltip.hover.left
            title="Attachment"
          >
            126318028397
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <ul class="message-list single-item">
          <li v-for="message in messages.messages" :key="message.id">
            <div class="d-flex align-items-center">
              <div class="img-wrap">
                <img :src="message.img" alt="" />
              </div>
              <span class="fw700">
                {{ message.sender }}
              </span>
            </div>
            <span class="d-block mt-16">
              {{ message.message }}
            </span>

            <template v-if="message.attachment">
              <b-button variant="tertiary" size="sm" class="mt-16">
                <i class="icon-paper fs16 mr-8"></i>
                2841 Ledger.pdf
              </b-button>
            </template>
            <span class="d-block ml-auto mt-20 fs11 text-gray-400 text-right">
              {{ message.dateTime }}
            </span>
          </li>
          <li>
            <div class="w-100 text-center">
              <svg
                version="1.1"
                id="L4"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 30 30"
                enable-background="new 0 0 0 0"
                xml:space="preserve"
              >
                <circle fill="#006EFF" stroke="none" cx="0" cy="14" r="4">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.1"
                  />
                </circle>
                <circle fill="#006EFF" stroke="none" cx="14" cy="14" r="4">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.2"
                  />
                </circle>
                <circle fill="#006EFF" stroke="none" cx="28" cy="14" r="4">
                  <animate
                    attributeName="opacity"
                    dur="1s"
                    values="0;1;0"
                    repeatCount="indefinite"
                    begin="0.3"
                  />
                </circle>
              </svg>
              <span class="text-gray-600">
                Soni is typing
              </span>
            </div>
          </li>
        </ul>
        <div class="message-composer single-item">
          <b-input-group>
            <b-form-textarea
              v-model="message"
              placeholder="Type in your message..."
              rows="1"
              max-rows="1"
            ></b-form-textarea>
            <div class="input-group-append">
              <div class="input-group-text">
                <b-button
                  variant="icon-transparent"
                  class="px-0 mr-16"
                  v-b-tooltip.hover.left
                  title="Attachment"
                  @click="popInput"
                >
                  <i class="icon-attachment fs16"></i>
                </b-button>
                <b-form-file ref="file" class="d-none" plain></b-form-file>
                <b-button
                  :disabled="btnDisabled"
                  variant="primary"
                  size="xs"
                >
                  Send
                </b-button>
              </div>
            </div>
          </b-input-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SupportTicketSingleItem",
  data() {
    return {
      message: null,
    };
  },
  components: {},
  computed: {
    messages() {
      return this.$store.getters.getTicketById(241);
    },
    btnDisabled() {
      if (this.message != null) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    popInput() {
      this.$refs.file.$el.click();
    },
  },
};
</script>
