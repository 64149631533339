var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "border-bottom mx-n20" }, [
      _c("div", { staticClass: "row align-items-center p-20" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "col-6 text-right" },
          [
            _c("b-button", { staticClass: "h40px p-12 mr-8" }, [
              _c("i", { staticClass: "icon-phone" })
            ]),
            _c("b-button", { staticClass: "btn-red h40px p-12 mr-8" }, [
              _c("i", { staticClass: "icon-delete" })
            ]),
            _c("b-button", { staticClass: "btn-green h40px p-12" }, [
              _c("i", { staticClass: "icon-booked" })
            ])
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "row pt-20" }, [
      _c(
        "div",
        { staticClass: "col-auto" },
        [
          _c(
            "b-button",
            {
              staticClass: "mt-5",
              attrs: { variant: "icon-sm" },
              on: { click: _vm.goBack }
            },
            [_c("i", { staticClass: "icon-previous fs9" })]
          )
        ],
        1
      ),
      _c("div", { staticClass: "col-10" }, [
        _c("h1", [
          _vm._v(
            " #204 - Status of some DZ, DL and XB shipments showing Delivered "
          )
        ]),
        _c("span", { staticClass: "d-block text-gray-600 fw500 py-20" }, [
          _vm._v(" AWB: "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "b-tooltip",
                  rawName: "v-b-tooltip.hover.left",
                  modifiers: { hover: true, left: true }
                }
              ],
              staticClass: "py-3 px-4 bg-gray-200 rounded-sm",
              attrs: { title: "Attachment" }
            },
            [_vm._v(" 126318028397 ")]
          )
        ])
      ])
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col" }, [
        _c(
          "ul",
          { staticClass: "message-list single-item" },
          [
            _vm._l(_vm.messages.messages, function(message) {
              return _c(
                "li",
                { key: message.id },
                [
                  _c("div", { staticClass: "d-flex align-items-center" }, [
                    _c("div", { staticClass: "img-wrap" }, [
                      _c("img", { attrs: { src: message.img, alt: "" } })
                    ]),
                    _c("span", { staticClass: "fw700" }, [
                      _vm._v(" " + _vm._s(message.sender) + " ")
                    ])
                  ]),
                  _c("span", { staticClass: "d-block mt-16" }, [
                    _vm._v(" " + _vm._s(message.message) + " ")
                  ]),
                  message.attachment
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "mt-16",
                            attrs: { variant: "tertiary", size: "sm" }
                          },
                          [
                            _c("i", { staticClass: "icon-paper fs16 mr-8" }),
                            _vm._v(" 2841 Ledger.pdf ")
                          ]
                        )
                      ]
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass:
                        "d-block ml-auto mt-20 fs11 text-gray-400 text-right"
                    },
                    [_vm._v(" " + _vm._s(message.dateTime) + " ")]
                  )
                ],
                2
              )
            }),
            _c("li", [
              _c("div", { staticClass: "w-100 text-center" }, [
                _c(
                  "svg",
                  {
                    attrs: {
                      version: "1.1",
                      id: "L4",
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      x: "0px",
                      y: "0px",
                      viewBox: "0 0 30 30",
                      "enable-background": "new 0 0 0 0",
                      "xml:space": "preserve"
                    }
                  },
                  [
                    _c(
                      "circle",
                      {
                        attrs: {
                          fill: "#006EFF",
                          stroke: "none",
                          cx: "0",
                          cy: "14",
                          r: "4"
                        }
                      },
                      [
                        _c("animate", {
                          attrs: {
                            attributeName: "opacity",
                            dur: "1s",
                            values: "0;1;0",
                            repeatCount: "indefinite",
                            begin: "0.1"
                          }
                        })
                      ]
                    ),
                    _c(
                      "circle",
                      {
                        attrs: {
                          fill: "#006EFF",
                          stroke: "none",
                          cx: "14",
                          cy: "14",
                          r: "4"
                        }
                      },
                      [
                        _c("animate", {
                          attrs: {
                            attributeName: "opacity",
                            dur: "1s",
                            values: "0;1;0",
                            repeatCount: "indefinite",
                            begin: "0.2"
                          }
                        })
                      ]
                    ),
                    _c(
                      "circle",
                      {
                        attrs: {
                          fill: "#006EFF",
                          stroke: "none",
                          cx: "28",
                          cy: "14",
                          r: "4"
                        }
                      },
                      [
                        _c("animate", {
                          attrs: {
                            attributeName: "opacity",
                            dur: "1s",
                            values: "0;1;0",
                            repeatCount: "indefinite",
                            begin: "0.3"
                          }
                        })
                      ]
                    )
                  ]
                ),
                _c("span", { staticClass: "text-gray-600" }, [
                  _vm._v(" Soni is typing ")
                ])
              ])
            ])
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "message-composer single-item" },
          [
            _c(
              "b-input-group",
              [
                _c("b-form-textarea", {
                  attrs: {
                    placeholder: "Type in your message...",
                    rows: "1",
                    "max-rows": "1"
                  },
                  model: {
                    value: _vm.message,
                    callback: function($$v) {
                      _vm.message = $$v
                    },
                    expression: "message"
                  }
                }),
                _c("div", { staticClass: "input-group-append" }, [
                  _c(
                    "div",
                    { staticClass: "input-group-text" },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.left",
                              modifiers: { hover: true, left: true }
                            }
                          ],
                          staticClass: "px-0 mr-16",
                          attrs: {
                            variant: "icon-transparent",
                            title: "Attachment"
                          },
                          on: { click: _vm.popInput }
                        },
                        [_c("i", { staticClass: "icon-attachment fs16" })]
                      ),
                      _c("b-form-file", {
                        ref: "file",
                        staticClass: "d-none",
                        attrs: { plain: "" }
                      }),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.btnDisabled,
                            variant: "primary",
                            size: "xs"
                          }
                        },
                        [_vm._v(" Send ")]
                      )
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-6" }, [
      _c("span", { staticClass: "text-gray-600" }, [
        _vm._v(" Case: "),
        _c("span", { staticClass: "text-gray-900" }, [
          _vm._v(" Wrong Address Correction Charges ")
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }